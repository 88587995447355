<template>
  <div class="login-container">
    <h3>Бүртгүүлэх</h3>
    <el-alert
      title="Хэрэглэгчийн нэр эсвэл нууц үг буруу байна!"
      v-if="error"
      type="error"
    ></el-alert>
    <el-form v-on:submit.prevent="onSubmit">
      <el-form-item prop="email" label="И-мэйл">
        <el-input v-model="username"></el-input>
      </el-form-item>
      <el-form-item prop="pass" label="Нууц үг">
        <el-input
          v-model="password"
          type="password"
          @keyup.enter.native="submit()"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          v-loading="load"
          :disabled="load"
          type="primary"
          @click="submit()"
          @keyup.enter="submit()"
          >Бүртгүүлэх</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { signUp } from "@/utils/auth.js";
export default {
  name: "SignUp",
  data() {
    return {
      load: false,
      valid: false,
      error: false,
      username: "",
      password: "",
      passwordVisible: false
    };
  },
  computed: {
    emailRules() {
      return [
        v => !!v || "E-mail is required",
        v => /.+@.+/.test(v) || "E-mail must be valid"
      ];
    },
    passwordRules() {
      return {
        required: value => !!value || "Required.",
        min: v => v.length >= 8 || "Min 8 characters",
        emailMatch: () => "The email and password you entered don't match"
      };
    }
  },
  methods: {
    submit() {
      this.load = true;
      signUp(this.username, this.password);
    }
  }
};
</script>
